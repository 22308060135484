<template>
  <div v-if="variant === 'paywalled_archive_page' && !subscriber && !loggingIn">
    <div v-if="newsletter.paywall_form_text">
      <div v-html="newsletter.paywall_form_text"></div>
    </div>
    <action-button
      v-if="purchaseUrl"
      :text="
        newsletter.paywall_button_text ||
        newsletter.subscription_button_text ||
        (newsletter.paid_subscription_free_trial_duration
          ? 'Subscribe and start a free trial'
          : 'Subscribe')
      "
      color="blue"
      :href="purchaseUrl"
    />
    <div
      style="
        margin-top: 0.5rem;
        margin-bottom: 2rem;
        text-align: center;
        cursor: pointer;
      "
      @click="loggingIn = true"
    >
      Already a paid subscriber? Click here to log in.
    </div>
  </div>
  <div v-else class="widget">
    <div style="text-align: center; font-size: 1.125rem; line-height: 1.75rem">
      <form
        v-if="
          state === 'not_subscribed' ||
          state === 'pending_subscription' ||
          state === 'pending_validation' ||
          state === 'errored_email' ||
          state === 'invalid_email' ||
          state === 'invalid_email__regex' ||
          state === 'invalid_subscription'
        "
        @submit.prevent="() => disabled || subscribe()"
      >
        <p class="heading">
          {{ newsletter.subscription_form_text }}
        </p>

        <input
          type="submit"
          value="submit"
          style="position: absolute; left: -9999px"
        />

        <template v-for="field in sortedMetadataFields" :key="field.id">
          <label v-if="field.field_type === 'checkbox'">
            <input
              type="checkbox"
              :value="metadata[field.key]"
              :required="field.is_required"
              :readonly="state === 'pending_subscription'"
              @input="(event) => handleMetadataInput(field.key, event)"
            />
            <span>{{ field.placeholder || field.key }}</span>
          </label>

          <div v-else-if="field.field_type === 'text'" class="field">
            <label :for="`sfi-${field.id}`" class="sr-only">
              {{ placeholder(field) }}
            </label>

            <input
              :id="`sfi-${field.id}`"
              :value="metadata[field.key]"
              type="text"
              :required="field.is_required"
              :placeholder="placeholder(field)"
              :readonly="state === 'pending_subscription'"
              :autocomplete="
                {
                  name: 'name',
                  first_name: 'given-name',
                  last_name: 'family-name',
                }[field.key] || 'off'
              "
              @input="(event) => handleMetadataInput(field.key, event)"
            />
          </div>

          <div v-else-if="field.field_type === 'select'" class="field">
            <label :for="`sfi-${field.id}`" class="sr-only">
              {{ placeholder(field) }}
            </label>

            <select
              :id="`sfi-${field.id}`"
              :value="metadata[field.key]"
              :required="field.is_required"
              @input="(event) => handleMetadataInput(field.key, event)"
            >
              <option value="" disabled selected>
                {{ placeholder(field) }}
              </option>
              <option
                v-for="(option, i) in field.options"
                :key="i"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </template>

        <div class="field">
          <label for="sfi-email" class="sr-only">
            {{ t("Your email (you@example.com)") }}
          </label>

          <input
            id="sfi-email"
            required
            :value="emailAddress"
            type="email"
            :placeholder="t('Your email (you@example.com)')"
            :readonly="state === 'pending_subscription'"
            autocomplete="email"
            @input="handleInput"
          />
        </div>
      </form>
      <span v-if="state === 'unpaid'">
        To finish subscribing to <strong>{{ newsletter.name }}</strong
        >, click below to upgrade your subscription.
      </span>
      <span
        v-if="
          state === 'regular' &&
          newsletter.paid_subscriptions_status === 'active'
        "
      >
        You're subscribed to <strong>{{ newsletter.name }}</strong
        >, but can upgrade to a paid subscription if you'd like!
      </span>
      <span v-else-if="state === 'regular'">
        You're subscribed to <strong>{{ newsletter.name }}</strong
        >!
      </span>
      <span v-if="state === 'trialed'">
        You're currently on a free trial of
        <strong>{{ newsletter.name }}</strong
        >.
      </span>
      <span v-if="state === 'unsubscribed'">
        You unsubscribed from
        <strong>{{ newsletter.name }}</strong
        >.
      </span>
      <span v-if="state === 'past_due'">
        Your payment to <strong>{{ newsletter.name }}</strong> is past due!
      </span>
      <span v-if="state === 'churned'">
        Your premium subscription to <strong>{{ newsletter.name }}</strong> has
        expired, but you can upgrade again if you'd like.
      </span>
      <span v-if="state === 'churning'">
        Your premium subscription to <strong>{{ newsletter.name }}</strong> will
        expire at the end of the current billing cycle.
      </span>
      <span v-if="state === 'paused'">
        You've paused your subscription, but you can resume it if you'd like!
      </span>
      <span v-if="state === 'unactivated'">
        Please check your inbox for a confirmation email!
      </span>
      <span v-if="state === 'premium'">
        You're now logged in as a premium subscriber of
        <strong>{{ newsletter.name }}</strong
        >!
      </span>
      <span v-if="state === 'gifted'">
        The author of <strong>{{ newsletter.name }}</strong> has gifted you a
        free premium subscription.
      </span>
      <span v-if="state === 'disabled'">
        You cannot subscribe to this newsletter.
      </span>
      <span
        v-if="
          state === 'spammy' || state === 'pending_email_address_confirmation'
        "
      >
        Your email address has been marked as spammy.
      </span>
      <span v-if="state === 'awaiting_email_address_confirmation'">
        Check your inbox for an email to confirm your address.
      </span>
    </div>

    <div class="button-container">
      <action-button
        v-if="state === 'pending_validation'"
        text="Validating..."
        color="blue"
        pending
      />
      <action-button
        v-if="state === 'pending_subscription'"
        :text="
          newsletter.subscription_button_text ||
          (newsletter.paid_subscription_free_trial_duration
            ? t('Subscribe and start a free trial')
            : t('Subscribe'))
        "
        color="blue"
        pending
        @click="() => disabled || subscribe()"
      />
      <action-button
        v-if="state === 'not_subscribed'"
        :text="
          newsletter.subscription_button_text ||
          (newsletter.paid_subscription_free_trial_duration
            ? t(
                `Subscribe and start a free trial for {newsletter.paid_subscription_free_trial_duration} days`
              ).replace(
                '{newsletter.paid_subscription_free_trial_duration}',
                String(newsletter.paid_subscription_free_trial_duration)
              )
            : variant === 'paywalled_archive_page'
            ? t('Log in')
            : t('Subscribe'))
        "
        color="blue"
        :disabled="disabled"
        @click="() => disabled || subscribe()"
      />
      <action-button
        v-if="state === 'unsubscribed'"
        :text="t('Resubscribe')"
        color="yellow"
        @click="() => subscriber && resubscribe(subscriber.id)"
      />
      <action-button
        v-if="state === 'invalid_email__regex'"
        :text="t('That email is malformed! Please double check it.')"
        color="red"
        disabled
      />
      <action-button
        v-if="state === 'invalid_subscription'"
        :text="t('That email didn\'t work! Please try another.')"
        color="red"
        disabled
      />
      <div v-if="state === 'unactivated'" class="unactivated-buttons">
        <sniper-link
          :sender.attr="
            newsletter.email_domain
              ? newsletter.email_address
              : `${newsletter.username}@buttondown.email`
          "
          :recipient.attr="emailAddress"
          template="Take me to {provider}"
        ></sniper-link>
        <action-button
          :text="t('I have confirmed my email!')"
          color="yellow"
          @click="checkSubscriber"
        />
      </div>
      <action-button
        v-if="state === 'trialed' && upgradeUrl"
        :href="upgradeUrl"
        :text="t('Upgrade subscription')"
        color="yellow"
      />

      <action-button
        v-if="
          (state === 'unpaid' ||
            (state === 'regular' &&
              newsletter.paid_subscriptions_status === 'active')) &&
          upgradeUrl
        "
        :href="upgradeUrl"
        :text="t(upgradeText)"
        color="blue"
      />
      <action-button
        v-if="state === 'past_due' && manageSubscriptionUrl"
        :href="manageSubscriptionUrl"
        :text="t('Manage subscription')"
        color="yellow"
      />
      <action-button
        v-if="state === 'churning' && manageSubscriptionUrl"
        :href="manageSubscriptionUrl"
        :text="t('Manage subscription')"
        color="yellow"
      />
      <action-button
        v-if="state === 'churned' && upgradeUrl"
        :href="upgradeUrl"
        :text="t('Upgrade subscription')"
        color="yellow"
      />
      <action-button
        v-if="state === 'paused' && manageSubscriptionUrl"
        :href="manageSubscriptionUrl"
        :text="t('Manage subscription')"
        color="yellow"
      />
      <action-button
        v-if="state === 'premium' && manageSubscriptionUrl"
        :href="manageSubscriptionUrl"
        :text="t('Manage subscription')"
        color="green"
      />
      <action-button
        v-if="state === 'regular' && archiveUrl"
        :href="archiveUrl"
        :text="t('View archives')"
        color="blue"
      />
      <action-button
        v-if="state === 'churning' && archiveUrl"
        :href="archiveUrl"
        :text="t('View archives')"
        color="yellow"
      />
      <action-button
        v-if="
          (state === 'premium' || state === 'gifted' || state === 'churning') &&
          archiveUrl &&
          variant === 'subscribe_page'
        "
        :href="archiveUrl"
        :text="t('View archives')"
        color="green"
      />
      <action-button
        v-if="
          (state === 'premium' || state === 'gifted' || state === 'churning') &&
          emailUrl &&
          variant === 'archive_page'
        "
        :href="emailUrl"
        :text="t('Read full article')"
        color="green"
      />
      <action-button
        v-if="state === 'disabled'"
        :href="`mailto:${newsletter.email_address}`"
        :text="t('Contact author')"
        color="red"
      />
      <action-button
        v-if="state === 'errored_email'"
        :text="error || t('An error occurred. Please try again.')"
        color="red"
      />
      <action-button
        v-if="
          state === 'spammy' ||
          state === 'invalid_email' ||
          state === 'pending_email_address_confirmation'
        "
        :pending="state === 'pending_email_address_confirmation'"
        :text="t('Confirm email')"
        color="red"
        @click="confirmEmailAddress"
      />
      <action-button
        v-if="state === 'awaiting_email_address_confirmation'"
        :text="t('I confirmed my email!')"
        color="red"
        @click="checkSubscriber"
      />
      <action-button
        v-if="
          state === 'spammy' || state === 'pending_email_address_confirmation'
        "
        :href="`mailto:${newsletter.email_address}`"
        :text="t('Contact author')"
        color="red"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { MetadataField, Newsletter } from "@/types/newsletter";

import Translations from "../../translations.json";
import Button from "./Button.vue";
import {
  BasicSubscriber,
  extractSlug,
  mutableUrl,
  SubscribeFormVariant,
  SubscriptionState,
} from "./lib";

export default defineComponent({
  components: {
    "action-button": Button,
  },
  props: {
    newsletter: {
      type: Object as PropType<Newsletter>,
      required: true,
    },
    subscriber: {
      type: Object as PropType<BasicSubscriber | undefined>,
    },
    state: {
      type: String as PropType<SubscriptionState>,
      required: true,
    },
    variant: {
      type: String as PropType<SubscribeFormVariant>,
      required: true,
    },
    emailAddress: {
      type: String,
      required: true,
    },
    metadata: {
      type: Object,
      required: true,
    },
    confirmEmailAddress: {
      type: Function as PropType<(payload: MouseEvent) => void>,
      required: true,
    },
    checkSubscriber: {
      type: Function as PropType<(payload: MouseEvent) => void>,
      required: true,
    },
    subscribe: {
      type: Function as PropType<() => void>,
      required: true,
    },
    resubscribe: {
      type: Function as PropType<(subscriber_id: string) => void>,
      required: true,
    },
    error: String,
  },

  emits: ["update:emailAddress", "update:metadata"],

  data() {
    return {
      loggingIn: false,
    };
  },

  computed: {
    upgradeText(): keyof typeof Translations {
      if (this.variant === "subscribe_page") {
        return "Upgrade subscription";
      }
      if (this.variant === "archive_page") {
        return "Read full article";
      }
      if (this.variant === "paywalled_archive_page") {
        return "Log in";
      }
      return "Upgrade subscription";
    },
    disabled(): boolean {
      // Make sure all required fields (incl. the email itself) are present.
      return (
        this.newsletter.metadata_fields
          .filter((field) => field.is_required)
          .some((field) => !this.metadata[field.key]) || !this.emailAddress
      );
    },
    purchaseUrl(): string | null {
      return `https://buttondown.com/${this.newsletter.username}/buy`;
    },
    upgradeUrl(): string | null {
      return mutableUrl(this.newsletter, this.subscriber, "/upgrade/monthly");
    },
    manageSubscriptionUrl(): string | null {
      if (!this.subscriber) {
        return null;
      }
      return `/api/emails/manage-premium-subscription/${this.subscriber.id}`;
    },
    archiveUrl(): string | null {
      if (!this.newsletter.enabled_features.includes("archives")) {
        return null;
      }
      return mutableUrl(this.newsletter, this.subscriber, "/archive");
    },
    emailUrl(): string | null {
      const slug = extractSlug(window.location.href);
      return mutableUrl(this.newsletter, this.subscriber, `/archive/${slug}`);
    },
    sortedMetadataFields() {
      return [...this.newsletter.metadata_fields].sort(
        (a, b) => a.secondary_id - b.secondary_id
      );
    },
  },

  methods: {
    handleInput(event: Event) {
      const element = <HTMLInputElement>event.target;
      this.$emit("update:emailAddress", element.value);
    },
    handleMetadataInput(key: string, event: Event) {
      const element = <HTMLInputElement>event.target;
      this.$emit("update:metadata", {
        ...this.metadata,
        [key]: element.value,
      });
    },
    placeholder(field: MetadataField): string {
      if (!field.placeholder) {
        return field.key;
      }
      return `${field.placeholder}${field.is_required ? "*" : ""}`;
    },
    t(phraseKey: keyof typeof Translations): string {
      if (this.newsletter.locale === "en") {
        return String(phraseKey);
      }
      return Translations[phraseKey][this.newsletter.locale] || phraseKey;
    },
  },
});
</script>

<style lang="scss" scoped>
.widget {
  background-color: #f3f4f6;
}

form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.heading {
  &:empty {
    display: none;
  }
}
.field {
  display: flex;
  flex-direction: column;
}

input[type="email"],
input[type="text"],
select {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  color: #1f2937;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  flex: 1 1 0%;
  border-radius: 9999px;
  border-width: 1px;
  border-color: transparent;
}

.button-container {
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;

  @media (min-width: 768px) {
    > *:not(:first-child) {
      margin-left: 0.5rem;
    }
    flex-direction: row;
  }
}

.unactivated-buttons {
  display: flex;
  column-gap: 0.5rem;
  width: 100%;
}

.unactivated-buttons > * {
  box-sizing: border-box;
  // We want each to take up 50%, but there's a 0.5rem gap in between, so subtract that.
  flex: 1 0 calc(50% - 0.25rem);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

sniper-link::part(container) {
  width: auto;
}

sniper-link::part(button) {
  background-color: #d97706;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  border: none;
}

sniper-link::part(image) {
  background-color: white;
  padding: 2px;
  border-radius: 8px;
}

sniper-link::part(text) {
  color: white;
  font-weight: 500;
  width: max-content;
}

@media (max-width: 550px) {
  .unactivated-buttons {
    flex-direction: column;
    row-gap: 0.25rem;
  }

  .unactivated-buttons > * {
    flex: 1 0 100%;
  }
}
</style>
